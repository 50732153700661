import { MagnifyingGlassIcon } from "@heroicons/react/20/solid"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { pageUrl } from "common/lib/router"

export const SearchForm = () => {
  const t = useTranslations("layout.header")
  const regionCode = useRegionCode()
  const searchPath = pageUrl.view({ regionCode, pageSlug: "search" })

  return (
    <form action={searchPath} acceptCharset="UTF-8" method="get">
      <div className="relative mt-1 flex items-center rounded-sm border border-gray-300 bg-white lg:w-72">
        <label htmlFor="q" className="absolute hidden pl-2 lg:block">
          <MagnifyingGlassIcon name="Search" role="search" className="w-4" />
        </label>
        <input
          type="text"
          name="q"
          className="block w-full px-3 py-2.5 text-sm placeholder:text-gray-700 lg:py-1.5 lg:pl-8 lg:text-2xs lg:placeholder:text-gray-700"
          placeholder={t("search_placeholder")}
        />
        <MagnifyingGlassIcon
          name="Search"
          role="search"
          className="absolute right-2 w-5 lg:hidden"
        />
      </div>
    </form>
  )
}
