import clsx from "classnames"
import { usePageProps } from "common/hooks/data/usePageProps"
import type { AppProps } from "common/lib/getAppServerSideProps"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"
import { pageUrl } from "common/lib/router"

export const DesktopMenu = () => {
  const { navItems, pageSlug, regionCode } = usePageProps<AppProps>()
  const {
    section_nav: { custom_nav_link: customNavLink },
  } = useRegionConfig()
  const currentPathname = pageUrl.view({ regionCode, pageSlug })
  const isCustomNavLinkActive = currentPathname === customNavLink.link

  return (
    <nav
      className="-mb-1 hidden text-3xs font-bold text-gray-700 lg:flex lg:text-2xs 2xl:text-sm"
      data-testid="desktopMenu"
    >
      {navItems
        .sort((navItemA, navItemB) => navItemA.position - navItemB.position)
        .map(({ title, path }) => {
          const isCurrentPage = currentPathname === path
          return (
            <a
              key={path}
              href={path}
              className={clsx("mr-2.5 border-b-4 pb-2.5 hover:border-red-500 2xl:mr-4", {
                "border-red-500": isCurrentPage,
                "border-gray-50": !isCurrentPage,
              })}
            >
              {title}
            </a>
          )
        })}

      {customNavLink.enabled ? (
        <a
          className={clsx("ml-auto border-b-4 pb-2.5 hover:border-red-500", {
            "border-red-500": isCustomNavLinkActive,
            "border-gray-50": !isCustomNavLinkActive,
          })}
          href={customNavLink.link}
        >
          {customNavLink.title}
        </a>
      ) : null}
    </nav>
  )
}
