import { createContext, useContext } from "react"
import { NextRouter, useRouter as useNextRouter } from "next/router"

export const TestRouterContext = createContext<any>(null)

const ROUTES_PREFIX = "/pb"
export const HOME_PAGE_SLUG = "home-page"
export const DEFAULT_HOST = "https://theconversation.com"

export const pageUrl = {
  edit: ({ regionCode, pageSlug }: { regionCode: string; pageSlug: string }) =>
    `${ROUTES_PREFIX}/${regionCode}/${pageSlug}/edit`,
  /**
   * Returns a URL to the page view.
   * @param regionCode
   * @param pageSlug
   * @param timestamp - optional timestamp to bypass cache. It'll add a query param `_tcv` with the timestamp value.
   * @param prefix - whether to prepend the `/pb` routes prefix
   * @param host - optional host to use in the URL, including its protocol (e.g.: "https://theconversation.com")
   */
  view: ({
    regionCode,
    pageSlug,
    timestamp,
    prefix = false,
    host = "",
  }: {
    regionCode: string
    pageSlug: string
    timestamp?: string
    prefix?: boolean
    host?: string
  }) => {
    const region = regionCode ? `/${regionCode}` : ""
    const slug = pageSlug ? `/${pageSlug}` : ""
    const queryString = timestamp ? `?_tcv=${timestamp}` : ""
    const pathParts = prefix ? [ROUTES_PREFIX, region, slug] : [region, slug]
    const path = pathParts.join("")

    return host + path + queryString
  },
}

export const newslettersPage = {
  subscribePath: ({ regionCode, format }: { regionCode: string; format?: string }) => {
    const path = `/${regionCode}/newsletters/subscribe`
    return format ? `${path}.${format}` : path
  },
  newslettersPath: ({ regionCode }: { regionCode: string }) => {
    // TODO: we're prefixing the `/frontend` for now.
    return `/frontend/${regionCode}/newsletters`
  },
  managementPath: ({ regionCode }: { regionCode: string }) => {
    // TODO: we're prefixing the `/frontend` for now
    return `/frontend/${regionCode}/newsletters/subscriptions`
  },
}

export const sessions = {
  signInPath: ({ returnToPath }: { returnToPath?: string }) => {
    if (returnToPath) {
      return `/sign_in?return_to=${encodeURIComponent(returnToPath)}`
    }

    return `/sign_in`
  },
  signUpPath: ({ returnToPath }: { returnToPath?: string }) => {
    if (returnToPath) {
      return `/sign_up?return_to=${encodeURIComponent(returnToPath)}`
    }

    return `/sign_up`
  },
}

/**
 * Tiny wrapper around native NextJS useRouter from next/router.
 * It allows for easier testing, especially for Storyshots.
 *
 * If you plan to test component which uses `useRouter` you can wrap
 * it with `<TestRouterContext.Provider value={}>` and provide router
 * value through context provider.
 * @returns router
 */
export const useRouter = () => {
  const router = useNextRouter()
  const testRouter: NextRouter = useContext(TestRouterContext)

  return testRouter || router
}
